
import Rails from "@rails/ujs"

$(document).ready(function() {

  $('.new-bracket .game-top, .new-bracket .game-bottom').on('click', function() {
    var entrant = $(this);

    entrant.addClass('predicted-winner');
    
    const matchup = entrant.data('match-up');
    const entrant_id = entrant.data('entrant');
    console.log("Entrant id: " + entrant_id);

    const next = $("li[data-previous='" + matchup + "']");

    const current_entrant_id = next.data('entrant');
    console.log("current entrant: " + current_entrant_id);

    // Clear previous selections on this path
    if (typeof current_entrant_id != "undefined" && current_entrant_id != "") {
      const selections = $(".picking-round li[data-entrant='" + current_entrant_id + "']");

      selections.each(function() {
        const to_reset = $(this);
        const matchup = to_reset.data('match-up');
        console.log("clear: " + matchup);
        $('.winner.match-' + matchup + ' input').val('');
      });

      selections.html('');
      selections.data('entrant', '');
    }

    // Set match winner
    $(".winner.match-" + matchup + " input").val(entrant_id);


    next.html(entrant.html());
    next.attr('data-entrant', entrant_id);
    next.data('entrant', entrant_id);
    next.addClass('winner-selected');

    console.log(entrant_id);
  });


  if ($('#index_brackets_form').length == 1) {
    // const setupTime = new Date();
    // console.log('setting brackets timeout: ' + setupTime.getHours() + ':' + setupTime.getMinutes() + ':' + setupTime.getSeconds());
    
    setInterval(function () {
      //const triggerTime = new Date();
      //console.log('Reloading Brackets Page: ' + triggerTime.getHours() + ':' + triggerTime.getMinutes() + ':' + triggerTime.getSeconds());
      var elem = document.getElementById('index_brackets_form'); // or $('#myform')[0] with jQuery
      Rails.fire(elem, 'submit');
    }, 10000);
  }

  // $('form#new_bracket input[type="submit"]').on('click', function(e) {
  //   e.preventDefault();
  //   console.log('validating');
  
  //   $('.winner input').each(function() {
  //     var sel = $(this);

  //     var id = sel.data('match-up');
  //     console.log(id);
  //     console.log(sel.val());
  //   });

  //   //$('form#new_bracket').submit();
  //   return false;    
  // });

  $('.voting_round_entrant_predictions_votes input').on('change', function() {
    console.log('vote entered')
    var total = 0
    $('.voting_round_entrant_predictions_votes input').each(function(index, votes) {
      console.log(votes.value)
      if (votes.value.length > 0) 
        total += parseInt(votes.value)
    })

    $('#total_votes').val(total)
  })
});
