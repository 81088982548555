
import './best_in_place/best_in_place'
import './best_in_place/best_in_place.purr'

import Rails from "@rails/ujs"

$(document).ready(function() {
  $(".best_in_place").best_in_place();  

  $('.best_in_place').bind("ajax:success", function () { 
    console.log("best in place updated");
    //$('.remote-show').trigger('click');
    //$('#show_competition_form').trigger('submit.rails');
    var elem = document.getElementById('show_competition_form') // or $('#myform')[0] with jQuery
    Rails.fire(elem, 'submit');
  });
});
